<template>
  <Login/>

</template>


<script>
  import Login from '../components/Login'


  export default {
    name: 'Home',

    components: {
    
        Login,
        
  
    },
  }
</script>

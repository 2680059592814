<template>
  <v-app id="app">
   <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template>
          <v-list-item :to="{name: 'principal'}">
            <v-list-item-action>
              <v-icon>home</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Inicio
            </v-list-item-title>
          </v-list-item>
        </template>
        <template>
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
               
               <v-list-item-title>
                  <v-icon>mdi-account</v-icon>
                  Mi Perfil
               </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'perfil'}">
              <v-list-item-action>
                <v-icon>mdi-account-details</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Mis Datos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: ''}">
              <v-list-item-action>
                <v-icon>mdi-account-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Mis Postulaciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: ''}">
              <v-list-item-action>
                <v-icon>mdi-star-shooting</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Favoritos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          </template>
          <template>
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
               <v-list-item-title>
                  Promocionarme
               </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: ''}">
              <v-list-item-action>
                <v-icon>table_chart</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Elegir Plan
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: ''}">
              <v-list-item-action>
                <v-icon>table_chart</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Ver Estatus de Plan
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: ''}">
              <v-list-item-action>
                <v-icon>table_chart</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Mis Pagos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          </template>
      </v-list>
    </v-navigation-drawer>
    
        <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="grey darken-4"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
      
      
      >
         <v-img
          alt="Logo"
          id="milogo"
          class="shrink mr-2 "
          contain
          src="./assets/PNG2.png"
          transition="scale-transition"
          width="180"
        />
  
     

      </v-toolbar-title>
      <div class="social-buttons">
             <v-icon  style="margin-left:30px" 
             >mdi-facebook</v-icon>
           
           <v-icon 
           style="margin-left:10px">mdi-twitter</v-icon>
            <v-icon 
            style="margin-left:10px">mdi-linkedin</v-icon>
             <v-icon 
             style="margin-left:10px">mdi-instagram</v-icon>
             </div>
      <v-spacer></v-spacer>
      
           <v-btn
        target="_blank"
        text
      >
        <span class="mr-2 boton-tr">BUSCO TRABAJO</span>
   
      </v-btn>

      <v-btn
        target="_blank"
        text
      >
        <span class="mr-2 boton-tr">BUSCO PERSONAL</span>
       
      </v-btn>
        <v-btn
        target="_blank"
        text
        class="usuario"
      >
        <v-icon small>
         mdi-account
       </v-icon>
        <span class="mr-2">IVÁN</span>
       <v-icon small>
         mdi-chevron-down
       </v-icon>
    
      </v-btn>
      <v-btn icon>
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>
  
    <v-main>
      <v-container
        fluid
      >
        <v-slide-y-transition  mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer 
    color="grey darken-4"
    dark
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
    <v-col
    cols="12" class="white--text text-center">
     <v-img
          alt="Vacanta Logo"
          class="img-fluid"
          id="milogo"
          contain
          src="./assets/PNG2.png"
          transition="scale-transition"
          width="210"
          style="margin:auto; display:content;margin-top:4px"
        />

        <br>
    </v-col>
      <v-col
      cols="4" class="white--text text-center">
      <h3 >Oficinas</h3>
      <br>
      <p>Av. Politécnico Nacional #2345</p>
       <p>Chihuahua, Chih.</p>
      </v-col>
        <v-col
      cols="4" class="white--text text-center">
        <h3 >Nosotros</h3>
        <br>
          <p>Nuestra Empresa</p>
          <p>¿Cómo funciona?</p>
          <p>Preguntas Frecuentes</p>
 
      </v-col>
        <v-col
      cols="4" class="white--text text-center">
         <h3 >Socializa</h3>
         <br>
           <div>
            
             <v-icon color="white" >mdi-facebook</v-icon>
           
           <v-icon color="white" style="margin-left:10px">mdi-twitter</v-icon>
            <v-icon  color="white" style="margin-left:10px">mdi-linkedin</v-icon>
             <v-icon color="white"  style="margin-left:10px">mdi-instagram</v-icon>
             <br>
             <v-divider 
             style="margin-top:30px;"></v-divider>
              <br>
          <p class="white--text text-center"> Vacanta ® Derechos Reservados {{ new Date().getFullYear() }}</p>
        </div>
      </v-col>
     
    </v-row>
  </v-footer>
    
    
 
  </v-app>
</template>

<script>

export default {
  name: 'App',
 data() {
   return {
     drawer: null
   }
 },

};
</script>

<style scoped>

@media only screen and (min-width: 868px) {

} 

@media only screen and (max-width: 768px) {
#milogo{
  width: 140px !important;
}
.social-buttons{
  display: none;
}
.boton-tr{
  display: none;
}
.logo{
  display: none;
}
.texto-logo{
  font-size: 20px;
}
.usuario{
  margin-left: -120px;
}
.autocompletado-estado{
  display: none;
}
.autocompletado-tipo{
  display: none;
}
.autocompletado-puesto{
width: 74px;

}
::v-deep .autocompletado-puesto .v-label {
 font-size: 11.5px;

}
::v-deep .autocompletado-puesto .v-icon {
 font-size: 18px;
 margin-left: 26px;
}
::v-deep .autocompletado-ciudad .v-label {
 font-size: 13px;

}
::v-deep .autocompletado-ciudad .v-icon {
 font-size: 18px;
 margin-left: 26px;
}
.autocompletado-ciudad .v-label{
  font-size: 14px;
  width: 50px;
}
.autocompletado-ciudad{
width: 70px;

}
.boton-buscar{
  width: 86px;

}

} 

</style>
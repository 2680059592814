<template>
      <v-card
    color="grey darken-4"
    dark
    
    height="140px"
    class="mitarjeta"
    fluid
  >
    <v-toolbar >
      <v-container fluid class="micontenedor">
        <v-row >
          <v-col 
          cols="3">
   <v-autocomplete class="autocompletado-puesto" id="autocompletado"
      dense filled :items='items' v-model="values"
      outlined chips small-chips label="Puesto de trabajo"
      ></v-autocomplete>
          </v-col>
              <v-col >
   <v-autocomplete class="autocompletado-estado" id="autocompletado"
        dense filled v-model="estado" 
      outlined  chips small-chips label="Estado"
      ></v-autocomplete>
          </v-col>
              <v-col 
              cols="3">
   <v-autocomplete class="autocompletado-ciudad" color="white" id="autocompletado" 
      dense filled v-model="ciudad"
      outlined  label="Ciudad"
      ></v-autocomplete>
          </v-col>
    <v-col >
   <v-autocomplete class="autocompletado-tipo" id="autocompletado"
      dense filled v-model="tipoTrabajo" type="primary"
      outlined chips small-chips label="Tipo de Empleo"
      ></v-autocomplete>
          </v-col>
        
          <v-btn
          class="boton-buscar"
          id="autocompletado2"
          small
          color="teal accent-4">
            Buscar
            <v-icon style="margin-left:9px;">
              mdi-magnify
            </v-icon>
          </v-btn>
        
           
          
        

          

        </v-row>

       
      </v-container>
  
    </v-toolbar>
  </v-card>
</template>
<script>



export default {
    name: 'Buscador',
     data: () => ({
      items: ['Mercadotecnia', 'Sistemas', 'Contabilidad', 'Finanzas'],
      values: ['Sistemas', 'Contabilidad'],
      value: null,
    }),
  
}

</script>

<style scoped>
.mitarjeta{
  margin-top: -90px;
}
.micontenedor{
   margin-top:165px;
}
</style>
<style scoped>
#autocompletado2{
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
  

} 

@media only screen and (max-width: 768px) {
  .micontenedor{
   margin-top:155px;
}
  
#milogo{
  width: 140px !important;
}
.social-buttons{
  display: none;
}
.boton-tr{
  display: none;
}
.logo{
  display: none;
}
.texto-logo{
  font-size: 20px;
}
.usuario{
  margin-left: -120px;
}
.autocompletado-estado{
  display: none;
}
.autocompletado-tipo{
  display: none;
}
.autocompletado-puesto{
width: 74px;

}
::v-deep .autocompletado-puesto .v-label {
 font-size: 11.5px;

}
::v-deep .autocompletado-puesto .v-icon {
 font-size: 14px;
 margin-left: 26px;
}
::v-deep .autocompletado-ciudad .v-label {
 font-size: 13px;
 
}
::v-deep  .v-input__slot {
 width: 80px !important;
 margin-left: -7px
}
::v-deep .autocompletado-ciudad .v-icon {
 font-size: 14px;
 margin-left: 26px;
}
.autocompletado-ciudad .v-label{
  font-size: 12px;
  margin-left: -20px;
}
.autocompletado-ciudad{
width: 70px !important;

}
.boton-buscar{
  width: 86px;

}

} 

</style>
<template>
<v-layout style="margin-top:70px" align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
        <v-card>
            <v-toolbar dark color ="#14a6a6">
                
                <v-toolbar-title >      
                   <v-img 
                src="../assets/PNG2.png"
                width="200px"
                ></v-img>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="correo" autofocus color="accent" label="Email" required>
                </v-text-field>
                <v-text-field v-model="password" type="password" color="accent" label="Password" required>
                </v-text-field>
                
            </v-card-text>
            <v-card-actions class="px-3 pb-3">
                <v-flex text-cs-right>
                    <router-link :to="{name: 'principal'}">
                    <v-btn oncli color="#14a6a6">Ingresar</v-btn>
                    </router-link>
                </v-flex>
            </v-card-actions>
        </v-card>
        
        
    </v-flex> 


</v-layout>
    
</template>

<script>
import axios from 'axios';
export default {
    data (){
        return{
            correo:'',
           password:''
         
        }
    },
    methods:{
        ingresar(){
            axios.post('/auth/signin',{correo: this.correo, password: this.password})
            .then(response=>{
                return respuesta.data;
            })
            .then(data =>{
                this.$store.dispatch("guardarToken",data.tokenReturn);
                this.$router.push({name: 'home'});
            })
            .catch(error =>{
                console.log(error);
              
        });
    }
    }
}
</script>

<template >


  <v-container fluid>
    <Buscador
    ></Buscador>
    <br>
    <v-row>
    <v-col class="columna1" 
     sm="12" xs="12" md="4">
    <img src="http://ads.tiempo.com.mx/uploads/banners/300x100-banner-poramoracuu-2807.gif" alt="">
    <br>
        <img  src="http://ads.tiempo.com.mx/uploads/banners/cintillo-carvel-300-100-el-tiempo-06-marzo-2021_.gif" alt=""> <br>
    <img  src="http://ads.tiempo.com.mx/uploads/banners/el-tiempo-agosto.gif" alt="">
    <br>
    <br>
   
    <div>
       <h4 >Categoria</h4>
      <v-checkbox
              v-model="ca1"
              label="Mercadotecnica (26)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ca2"
              label="Tecnologías de la información (7)"
              color="blue"
              value="tecnologias"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ca3"
              label="Mercadotecnica (26)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ca4"
              label="Mercadotecnica (26)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ca5"
              label="Mercadotecnica (26)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
    </div>
  <br>
    <div>
       <h4 >Experiencia</h4>
      <v-checkbox
              v-model="ex4"
              label="Sin experiencia (1)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="1 año de experiencia (6)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="1 a 5 años de experiencia (4)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="5 a 10 años de experiencia (5)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="10 años o mas de experiencia (2)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>

    </div>
    <br>
    <div>
         <h4 >Sueldo</h4>
        <v-range-slider
            v-model="range"
            :max="max"
            :min="min"
            hide-details
            class="align-center"
          >
            <template v-slot:prepend>
              <v-text-field
                :value="range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(range, 0, $event)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(range, 1, $event)"
              ></v-text-field>
            </template>
          </v-range-slider>
    </div>
    <br>
    <div>
       <h4 >Conocimientos</h4>
      <v-checkbox
              v-model="ex4"
              label="Maneja ofimatíca (17)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="Software CRM (12)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="Trato con gente (4)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="2 o mas idiomas (2)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>
              <v-checkbox
              v-model="ex4"
              label="Redes Sociales (2)"
              color="blue"
              value="mercadotecnia"
              hide-details
            ></v-checkbox>

    </div>
    <br>
    <img  src="http://ads.tiempo.com.mx/uploads/banners/banner-oppm-_-el-tiempo.png" alt="">
    <br>
    </v-col>
    <v-col
    cols="12" sm="12" md="8">
      <v-card class="tarjeta" style="background-color:#ddd" v-for="post in posts" :key="post.id">
        <v-container>
          <v-row>
            <v-col
            cols="6">
             <h3 class> {{post.title}} </h3>
            </v-col>
            <v-col
            cols="6">
              <span class="botonAviso">TIEMPO COMPLETO</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
            cols="4">
              <p><v-icon >mdi-office-building</v-icon>Grupo Bafar </p>
            </v-col>
            <v-col
            cols="4">
              <p><v-icon >mdi-map-marker-circle</v-icon>  Chihuahua, Chih</p>
            </v-col>
            <v-col
            cols="4">
              <p><v-icon style="padding-left:-6px;" >mdi-cash</v-icon>  20 - 25k </p>
            </v-col>
             <v-col
            cols="12" style="margin-top:-20px;">
            <p>{{post.body}}</p></v-col>
          </v-row>
          <v-row>
            <v-col
            cols="4">
            <p><v-icon >mdi-calendar-range</v-icon>  Publicado hoy</p>
            </v-col>
              <v-col
            cols="4">
            <p><v-icon >mdi-eye-outline</v-icon>  243 Vistas</p>
            
            </v-col>
            <v-col
            cols="4">
            <v-btn class="botonPostularme" color="teal accent-4">
                Postularme <v-icon >mdi-account-check</v-icon> 
            </v-btn>
            
            
            </v-col>
            
           
          </v-row>


        </v-container>
        
    
      </v-card>
    </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
 import Buscador from '../components/Buscador'
 import axios from 'axios'

  export default {
    data () {
      return {
        posts: [],
        min: 0,
        max: 45000,
        range: [5000, 25000],
        puesto:"Gerente de Mercadotecnia"
      }
    
    },
    mounted(){
       axios.get('https://jsonplaceholder.typicode.com/posts')
        .then(response => {
           this.posts = response.data
        })
        .catch(error=>{
            console.log(error);
        })
    },
     components: {
    
        Buscador,
        
  
    },
     
  }
  
</script>

<style scoped>
@media only screen and (min-width: 868px) {

.columna2{
  padding:15px;
  
  border-radius:15px;
}
.tarjeta{
padding:15px; 
margin-top: 20px;
border-radius:15px;
}
.botonAviso{
  background-color:black; color:white; border-radius:20px; font-size:15px; padding:10px; margin-left:0px
}
} 

@media only screen and (max-width: 768px) {
  .columna2{
    margin-top: 25px;
  }
  .columna1{
    margin-bottom: 30px;
  }
  .tarjeta{
    margin-top: -50px;
    margin-bottom: 70px;
  }
.botonAviso{
  background-color:black; color:white; border-radius:18px; font-size:13px;padding:4px; 
}
.botonPostularme{
 width: 110px;
 font-size: 10px;
 margin-left: -25px !important;
}
} 

</style>
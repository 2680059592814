<template>
  <div>
    <v-toolbar
      dark
      prominent
       height="200px"
       class="hola"
    >
    <v-container>
      <v-row>
       <v-col>
 <div class="imagen">
    </div>
       </v-col>
       <v-col>
      <h2 class="nombre">Iván Eduardo Ayón Loera</h2>
      <h3 class="titulo" >Licenciado en Administración de las Tecnologias y las Comunicaciones</h3>
       </v-col>
      </v-row>
    </v-container>
      <v-spacer
      ></v-spacer>
<div class="espaciador">
</div>
    </v-toolbar>
    <v-container fluid style="margin-top:-12px">
    <v-row>
    <v-col>
      <div class="gris">
        <br>
        <p class="estado"><v-icon>mdi-map-marker</v-icon> Chihuahua, Chihuahua  </p>

        <p class="trabajando"><v-icon>mdi-briefcase</v-icon> Trabajando</p>

         <p class="percepcion"><v-icon>mdi-cash</v-icon> 20,000 - 25,000    </p>
         <br>
         <p class="idiomas"><v-icon>mdi-translate</v-icon> Español - Ingles</p>
    </div>

    </v-col>
    </v-row>
    </v-container>

  <br>
    <v-container>
      <v-row>
        <v-col >
          <h4><v-icon>mdi-message-text-clock</v-icon> Edad: 27 años{{edad}}</h4>
          <br>
          <h4><v-icon>mdi-email</v-icon> Email: ayonka44@gmail.com{{email}}</h4>
          <br>
          <h4><v-icon>mdi-google-translate</v-icon> Idiomas: Español{{idiomas}}</h4>
          <br>
          <h4><v-icon>mdi-school</v-icon> Grado de Estudios: Licenciado {{edad}}</h4>
          <br>
          <v-btn color="#14a6a6">Adjuntar CV <v-icon style="margin-left:15px">mdi-attachment</v-icon></v-btn>
          <br>
          <br>
           <v-btn color="#DD2C00">Editar Datos <v-icon style="margin-left:15px">mdi-pencil-outline</v-icon></v-btn>
        </v-col>
        <v-col>
        <v-card class="tarjeta-trabajos" >
              <h2>Mis Trabajos</h2>
              <br>
              <h4>Acura</h4>
              <br>
              <p>NOVIEMBRE 2016 - OCTUBRE 2018</p>
              <br>
              <h4>Servicios Automotrices S.A de C.V</h4>
              <br>
              <p>ABRIL 2019 - NOVIEMBRE 2019</p>
              <br>
              <h4>Honda</h4>
              <br>
              <p>NOVIEMBRE 2019 - OCTUBRE 2020</p>
              <br>
              <h4>APIMSA</h4>
              <br>
              <p>OCTUBRE 2020 - ACTUAL</p>
          </v-card>
          <br>
          <v-card class="tarjeta-trabajos" >
              <h2>Mis Cursos</h2>
              <br>
              <h4>Diplomado en metodologia 5's</h4>
              <br>
              <p>NOVIEMBRE 2016</p>
              <br>
              <h4>Diplomado ISO9004</h4>
              <br>
              <p>ABRIL 2019</p>
              
          </v-card>
        </v-col>
        <v-col>
          <v-card class="tarjeta-postulaciones">
            <h2>Mis Postulaciónes</h2>
            <br>
            <h4>Gerente de Ventas</h4>
            <p>Grupo Bafar - Chihuahua,Chihuahua</p>
            <br>
            <h4>Gerente de Ventas</h4>
            <p>Grupo Bimbo - Chihuahua,Chihuahua</p>
            <br>
            
          </v-card>

        </v-col>
      </v-row>

    </v-container>


    
    
  </div>
</template>
<script>
export default {
    data:{
  nombre: "Andrés Hernández Noguerón",
    },
  
}
</script>
<style scoped>
.tarjeta-postulaciones{
    padding: 25px;
  border-radius: 15px;
  background-color: #14a6a6;
}
.tarjeta-trabajos{
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.tarjeta-trabajos{
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.hola{
  border-radius: 25px;
}
.nombre{
    color: white;
    margin-left: 30px;
     margin-top: 70px;
   font-size: 30px;
}
#espacio{
  display:none;
}

.imagen{
    border: 4px solid white;
    width: 200px;
    height: 200px;
    margin-top: 80px;
    border-radius: 40px;
    display: flex;
    margin-left: 150px;
    background-color: #ddd;
}
.gris{
    background-color: darkgrey;
    width: 100%;
    height: 180px;
    border-radius: 25px;
}
.estado{
    margin-left: 390px;
}
.percepcion{
    margin-top: -5px;
    margin-left: 395px;
}
.trabajando{
    margin-top: -5px;
    margin-left: 395px;
}
.idiomas{
     margin-top: -25px;
    margin-left: 395px;
}
@media only screen and (max-width: 768px) {
.imagen{
border: 4px solid white;
width: 100px;
height: 100px;
margin-top: 20px;
border-radius: 40px;
display: flex;
margin-left: -0px;
background-color: #ddd;
}
.nombre{
  color: white;
margin-left: -30px;
font-size: 24px;
text-align: center;
margin-top: 0px;
}
.titulo{
 margin-top: 10px;
margin-left: -39px;
text-align: center;
font-size: 15px;
}
.estado{
  margin-left: 50px;
  margin-bottom: 10px;
}

.espacio{
  display: initial;
}
.percepcion{
  margin-left: 50px;

}
.trabajando{
  margin-left: 50px;
  margin-top: 0px;
}
.idiomas{
  margin-left: 50px;
  margin-top: -30px;
}
.gris{
  background-color: darkgrey;
width: 100%;
height: 170px;
border-radius: 25px;
}
}
</style>
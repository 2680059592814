import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    id: null
  },
  mutations: {
    setToken(state,token){
      state.token=token
    },
    setId(state,id){
      state.id=id
    }
  },
  actions: {
    guardarToken({commit}, token){
      commit("setToken", token)
      commit("setId",decode(token) )
      localStorage.setItem("token", token)
    },
    autoLogin({commit}){
      let token = localStorage.getItem("token");
      if(token) {
        commit("setToken", token)
        commit("setId",decode(token) )
      }
      router.push({name:'home'});
    },
    salir({commit}){
      commit("setToken", null);
      commit("setId", null);
      localStorage.removeItem("token");
      router.push({name: 'login'});
    }
  },
  modules: {
  }
})
